import styled from 'styled-components';
import theme from '../../theme';

const Paragraph = styled.p`
  font-family: ${props => props.theme.font.body};
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
`;

Paragraph.defaultProps = { theme };

export default Paragraph;
