import styled from 'styled-components';
import { utils } from '@enso/components';
import theme from '../../../theme';

const FooterContainer = styled.div`
  padding-top: 48px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  background-color: ${props => props.theme.color.backgroundDark};

  @media ${utils.device.laptop} {
    text-align: left;
    padding-top: 37px;
    padding-bottom: 48px;
    padding-left: 140px;
    padding-right: 140px;
  }
`;

FooterContainer.defaultProps = { theme };

export default FooterContainer;
