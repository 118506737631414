import styled from 'styled-components';
import { utils } from '@enso/components';
import TextDiv from '../../../components/styled/TextDiv';
import theme from '../../../theme';

const SocialNetworksContainer = styled(TextDiv)`
  margin-bottom: 20px;
  font-size: 11px;
  letter-spacing: -0.08px;
  color: ${props => props.theme.color.textSecondary};

  @media ${utils.device.laptop} {
    margin-bottom: 0;
    text-align: right;
  }
`;

SocialNetworksContainer.defaultProps = { theme };

export default SocialNetworksContainer;
