import React from 'react';

import { Grid, Col } from '@enso/components';

import FooterContainer from '../styled/FooterContainer';
import Copyright from '../styled/Copyright';
import SocialNetworksContainer from '../styled/SocialNetworksContainer';
import SocialNetworkLink from '../styled/SocialNetworkLink';
import i18n from '../../../../i18n';

const Footer = function() {
  return (
    <FooterContainer>
      <Grid sm="column" md="row-reverse">
        <Col>
          <SocialNetworksContainer>
            <span>{i18n.t('confirmedAccount.footer.followUs')}</span>
            <SocialNetworkLink
              href="https://mx.linkedin.com/company/enso-fintech"
              target="_blank"
            >
              <img src="/images/linkedin-enso.svg" alt="Linkedin logo" />
            </SocialNetworkLink>
            <SocialNetworkLink
              href="https://www.facebook.com/EnsoFintech"
              target="_blank"
            >
              <img src="/images/facebook-enso.svg" alt="Facebook logo" />
            </SocialNetworkLink>
            <SocialNetworkLink
              href="https://www.instagram.com/Enso.Fintech/"
              target="_blank"
            >
              <img src="/images/instagram-enso.svg" alt="Instagram logo" />
            </SocialNetworkLink>
          </SocialNetworksContainer>
        </Col>
        <Col>
          <Copyright>{i18n.t('confirmedAccount.footer.copyright')}</Copyright>
        </Col>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
