import styled from 'styled-components';

import { utils } from '@enso/components';

const HeroLogo = styled.img`
  display: block;
  width: 100px;
  margin: auto;
  margin-bottom: 40px;

  @media ${utils.device.laptop} {
    width: 120px;
    margin-bottom: 95px;
  }
`;

export default HeroLogo;
