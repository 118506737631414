import styled from 'styled-components';

import { utils } from '@enso/components';
import Paragraph from '../../../components/styled/Paragraph';
import theme from '../../../theme';

const PerkBody = styled(Paragraph)`
  text-align: center;
  color: ${props => props.theme.color.textDark};

  @media ${utils.device.laptop} {
    font-size: 16px;
    margin-left: 22px;
    text-align: left;
  }
`;

PerkBody.defaultProps = { theme };

export default PerkBody;
