import './reset.css';
import './layout.css';

import React from 'react';

const Layout = (props: React.Props<React.ElementType>) => (
  <main>{props.children}</main>
);

export default Layout;
