import React from 'react';

import { Grid, Col } from '@enso/components';
import i18n from '../../../../i18n';

import HeroContainer from '../styled/HeroContainer';
import HeroLogo from '../styled/HeroLogo';
import SuccessIcon from '../styled/SuccessIcon';
import HeroTitle from '../styled/HeroTitle';
import HeroSubtitle from '../styled/HeroSubtitle';

const Hero = function() {
  return (
    <HeroContainer>
      <HeroLogo src="/images/logo-enso.svg" alt="enso logo" />
      <Grid xs="column" md="row-reverse">
        <Col>
          <SuccessIcon src="/images/mailok.svg" alt="mail ok symbol" />
        </Col>
        <Col md="2">
          <HeroTitle>{i18n.t('confirmedAccount.hero.title')}</HeroTitle>
          <HeroSubtitle>
            {i18n.t('confirmedAccount.hero.subtitle')}
          </HeroSubtitle>
        </Col>
      </Grid>
    </HeroContainer>
  );
};

export default Hero;
