import styled from 'styled-components';

import { utils } from '@enso/components';
import Paragraph from '../../../components/styled/Paragraph';
import theme from '../../../theme';

const PerkTitle = styled(Paragraph)`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.color.textDark};

  @media ${utils.device.laptop} {
    font-size: 16px;
    margin-left: 22px;
    text-align: left;
  }
`;

PerkTitle.defaultProps = { theme };

export default PerkTitle;
