import React from 'react';

import Layout from '../../components/Layout';

import ConfirmedAccountContainer from './styled/ConfirmedAccountContainer';
import Hero from './components/Hero';
import Content from './components/Content';
import Footer from './components/Footer';

const ConfirmedAccount = () => (
  <Layout>
    <ConfirmedAccountContainer>
      <Hero />
      <Content />
      <Footer />
    </ConfirmedAccountContainer>
  </Layout>
);

export default ConfirmedAccount;
