import styled from 'styled-components';

import { utils } from '@enso/components';
import Paragraph from '../../../components/styled/Paragraph';
import theme from '../../../theme';

const HeroSubtitle = styled(Paragraph)`
  font-family: ${props => props.theme.font.subtitle};
  font-size: 18px;
  line-height: 1.39;
  text-align: center;
  color: ${props => props.theme.color.textDark};
  margin-bottom: 24px;

  @media ${utils.device.laptop} {
    text-align: left;
  }
`;

HeroSubtitle.defaultProps = { theme };

export default HeroSubtitle;
