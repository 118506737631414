import styled from 'styled-components';

import { utils } from '@enso/components';
import Header from '../../../components/styled/Header';
import theme from '../../../theme';

const HeroTitle = styled(Header)`
  font-size: 35px;
  line-height: 1.14;
  text-align: center;
  color: ${props => props.theme.color.textDark};
  margin-bottom: 12px;

  @media ${utils.device.laptop} {
    margin-bottom: 25px;
    font-size: 70px;
    text-align: left;
    line-height: 1.07;
  }
`;

HeroTitle.defaultProps = { theme };

export default HeroTitle;
