import styled from 'styled-components';
import theme from '../../theme';

const Header = styled.h1`
  font-family: ${props => props.theme.font.title};
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

Header.defaultProps = { theme };

export default Header;
