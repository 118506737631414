import styled from 'styled-components';
import { utils } from '@enso/components';

const ContentImage = styled.img`
  margin-bottom: 37px;
  width: 100%;

  @media ${utils.device.laptop} {
    margin-top: -11px;
    margin-bottom: 0;
    align-self: flex-end;
    height: 532px;
    width: 490px;
  }
`;

export default ContentImage;
