import React from 'react';

import PerkContainer from '../styled/PerkContainer';
import PerkImage from '../styled/PerkImage';
import PerkTitle from '../styled/PerkTitle';
import PerkBody from '../styled/PerkBody';
import { Grid, Col } from '@enso/components';

const Perk = function(props: PerkProps) {
  return (
    <PerkContainer>
      <Grid xs="column" md="row">
        <Col>
          <PerkImage src={props.src} />
        </Col>
        <Col md="75">
          <PerkTitle>{props.title}</PerkTitle>
          <PerkBody>{props.body}</PerkBody>
        </Col>
      </Grid>
    </PerkContainer>
  );
};

type PerkProps = {
  src: string;
  title: string;
  body: string;
};

export default Perk;
