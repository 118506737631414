import i18n from 'i18next';

i18n.init({
  lng: 'es',
  resources: {
    es: {
      translation: {
        confirmedAccount: {
          hero: {
            title: '¡Tu cuenta ha sido confirmada!',
            subtitle: 'Sigue disfrutando de Enso con toda seguridad.',
          },
          content: {
            title: 'Algunas ideas para usar Enso',
            perks: [
              {
                title: '¿Compraste los boletos para el festival de música?',
                body:
                  'Crea un grupo con tus amigos y solicita el dinero de cada boleto.',
              },
              {
                title:
                  '¿Fueron a tomar unos drinks y a Juan se le olvido su cartera?',
                body: 'Solicítale por Enso lo que te debe.',
              },
              {
                title: '¿Hicieron el super por internet?',
                body: 'Envía el pago a tu roomie.',
              },
            ],
          },
          footer: {
            followUs: 'Síguenos en: ',
            copyright:
              'Copyright © 2019 Grupo Finve S.A.P.I. de C.V. Todos los derechos reservados.',
          },
        },
      },
    },
  },
});

export default i18n;
