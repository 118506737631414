import styled from 'styled-components';
import { utils } from '@enso/components';

const SuccessIcon = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 30px;

  @media ${utils.device.laptop} {
    width: 132px;
  }
`;

export default SuccessIcon;
