import React from 'react';

import { Grid, Col } from '@enso/components';

import i18n from '../../../../i18n';
import ContentContainer from '../styled/ContentContainer';
import ContentImage from '../styled/ContentImage';
import ContentTitle from '../styled/ContentTitle';
import Perk from './Perk';

const Content = function() {
  return (
    <ContentContainer>
      <Grid xs="column" md="row-reverse">
        <Col>
          <ContentImage
            src="/images/01-iphone.png"
            alt="iphone with the enso app"
          />
        </Col>
        <Col>
          <ContentTitle>
            {i18n.t('confirmedAccount.content.title')}
          </ContentTitle>
          <Perk
            src="/images/perk-0.svg"
            title={i18n.t('confirmedAccount.content.perks.0.title')}
            body={i18n.t('confirmedAccount.content.perks.0.body')}
          />
          <Perk
            src="/images/perk-1.svg"
            title={i18n.t('confirmedAccount.content.perks.1.title')}
            body={i18n.t('confirmedAccount.content.perks.1.body')}
          />
          <Perk
            src="/images/perk-2.svg"
            title={i18n.t('confirmedAccount.content.perks.2.title')}
            body={i18n.t('confirmedAccount.content.perks.2.body')}
          />
        </Col>
      </Grid>
    </ContentContainer>
  );
};

export default Content;
