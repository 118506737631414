import styled from 'styled-components';
import Paragraph from '../../../components/styled/Paragraph';
import theme from '../../../theme';

const Copyright = styled(Paragraph)`
  font-size: 11px;
  letter-spacing: -0.08px;
  color: ${props => props.theme.color.textSecondary};
`;

Copyright.defaultProps = { theme };

export default Copyright;
