import styled from 'styled-components';

import { utils } from '@enso/components';
import Paragraph from '../../../components/styled/Paragraph';
import theme from '../../../theme';

const ContentTitle = styled(Paragraph)`
  margin-left: 26px;
  margin-right: 26px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.color.textDark};

  @media ${utils.device.laptop} {
    margin-left: 0;
    text-align: left;
    font-size: 30px;
  }
`;

ContentTitle.defaultProps = { theme };

export default ContentTitle;
